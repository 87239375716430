import {
  Box,
  Button,
  InputAdornment,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Iconify from '../../components/iconify/Iconify';
import ModalBillComprobante from './Modals/ModalBillComprobante';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<Iconify icon="mdi:expand-more" />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const TableBillsActas = ({ data, rowsPerpage, page, setAttachFilesLocal, downloadAllFiles, setShowModal, attachFilesActa }) => {

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event, billFiles) => {
    setAnchorEl(event.currentTarget);
    setAttachFilesLocal(billFiles);
  };
  const handleClose = (_, reason) => {
    if (reason !== undefined) {
      setAttachFilesLocal(attachFilesActa)
    }
    setAnchorEl(null);
  };
  const [showModalBillComprobante, setShowModalBillComprobante] = useState(false)
  const [comprobante, setComprobante] = useState(null)
  const handleCloseBillComprobante = () => {
    setAttachFilesLocal([])
    setShowModalBillComprobante(false)
  }
  const handleShowModalBillComprobante = (idComprobante) => {
    setComprobante(idComprobante)
    setShowModalBillComprobante(true)
  }
  const [bills, setBills] = useState(null);
  const [searched, setSearched] = useState('');
  const requestSearch = (value) => {
    if (value !== '') {
      const filteredRows = data.facturas?.filter(
        (row) =>
          String(row.idcomprobante)
            ?.toLowerCase()
            .match(new RegExp(String(value.toLowerCase()), 'g')) ||
          String(row.iddctopro)
            ?.toLowerCase()
            .match(new RegExp(String(value.toLowerCase()), 'g')) ||
          String(row.nombreProveedor)
            ?.toLowerCase()
            .match(new RegExp(String(value.toLowerCase()), 'g')) ||
          String(row.descripcion)
            ?.toLowerCase()
            .match(new RegExp(String(value.toLowerCase()), 'g'))
      );
      setBills(filteredRows);
      setSearched(value);
    } else {
      setBills(data?.facturas);
      setSearched('');
    }
  };
  useEffect(() => {
    if (data?.facturas.length > 0) {
      setBills(data?.facturas);
    }
  }, [data]);


  return (
    <Box>
      <Box mb={2}>
        <TextField
          size="small"
          id="standard-bare"
          variant="outlined"
          placeholder="Filtrar..."
          fullWidth
          value={searched}
          onChange={(e) => requestSearch(e.target.value)}
          InputProps={{
            style: { backgroundColor: 'white', padding: '0' },
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20, marginLeft: 3 }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Stack direction={'row'} justifyContent={'space-between'} mb={1} p={2} borderBottom={'1px solid gray'} px={4}>
        <Typography fontWeight={700} fontSize={'0.9rem'} component={'p'} flexBasis={"14%"} display={{ xs: 'none', sm: 'block' }}>
          Egreso
        </Typography>
        <Typography fontWeight={700} fontSize={'0.9rem'} component={'p'} flexBasis={"15%"}>
          Dcto
        </Typography>
        <Typography fontWeight={700} fontSize={'0.9rem'} component={'p'} flexBasis={{ xs: '50%', sm: "22%" }}>
          Proveedor
        </Typography>
        <Typography display={{ xs: 'none', sm: 'block' }} fontWeight={700} fontSize={'0.9rem'} component={'p'} flexBasis={"20%"}>
          Concepto{' '}
        </Typography>
        <Typography display={{ xs: 'none', lg: 'block' }} fontWeight={700} fontSize={'0.9rem'} component={'p'} flexBasis={isMobile ? "15%" : "8%"}
          textAlign={'right'}
        >
          Valor Pagado
        </Typography>
        <Typography display={{ xs: 'none', md: 'block' }} fontWeight={700} fontSize={'0.9rem'} component={'p'} flexBasis={"9%"}
          textAlign={'right'}
        >
          Valor Acta
        </Typography>
      </Stack>
      {
        bills?.slice(page * rowsPerpage, page * rowsPerpage + rowsPerpage).map((bill, index) => (
          <Accordion key={index} sx={{ mb: 1 }}>
            <AccordionSummary>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                spacing={1}
                flexGrow={1}
                overflow={'hidden'}
                gap={2}
              >
                <Typography fontWeight={500} fontSize={'0.8rem'} component={'p'} flexShrink={0}
                  flexBasis={"15%"} display={{ xs: 'none', sm: 'block' }}>
                  {bill.idcomprobante}
                </Typography>
                <Typography fontWeight={500} fontSize={'0.8rem'} component={'p'}
                  flexShrink={0}
                  flexBasis={"14%"}

                >
                  {bill.iddctopro}
                </Typography>
                <Typography

                  fontWeight={500}
                  fontSize={'0.8rem'}
                  component={'p'}
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: { xs: '120px', sm: 'none' } }}
                  flexShrink={0}
                  flexBasis={{ xs: '50%', sm: "22%" }}

                >
                  {bill.nombreProveedor}
                </Typography>
                <Typography
                  display={{ xs: 'none', sm: 'block' }}
                  fontWeight={500}
                  fontSize={'0.8rem'}
                  component={'p'}
                  flexShrink={0}
                  sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  flexBasis={"20%"}
                >
                  {bill.descripcion[0].toUpperCase() + bill.descripcion.slice(1, 30)}
                </Typography>
                <Typography
                  display={{ xs: 'none', md: 'block' }}
                  fontWeight={500}
                  fontSize={'0.8rem'}
                  component={'p'}
                  flexShrink={0}
                  flexBasis={isMobile ? "15%" : "8%"}
                  textAlign={'right'}
                  style={{ marginRight: '10px' }}
                >
                  {bill?.valorpago?.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                  })}
                </Typography>
                <Typography
                  display={{ xs: 'none', lg: 'block' }}
                  fontWeight={500}
                  fontSize={'0.8rem'}
                  component={'p'}
                  flexShrink={0}
                  flexBasis={"9%"}
                  textAlign={'right'}
                  style={{ marginRight: '10px' }}
                >
                  {bill?.valoracta?.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                  })}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                justifyContent={{ xs: 'flex-start', md: 'space-between' }}
                spacing={{ xs: 0, sm: 2, md: 2 }}
                p={2}
                flexWrap={'wrap'}
                gap={1}
              >
                {
                  isMobile ? (
                    <>
                      <Stack direction={'column'} spacing={0} flexGrow={{ md: 1 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Egreso
                          </Typography>
                          <Link onClick={() => handleShowModalBillComprobante(bill.idcomprobante)} sx={{ cursor: 'pointer' }}>
                            <Typography fontSize={'0.8rem'} fontWeight={700}>{bill.idcomprobante}</Typography>
                          </Link>
                        </Stack>

                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Dcto
                          </Typography>
                          <Typography fontSize={'0.8rem'}>{bill.iddctopro}</Typography>
                        </Stack>

                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Beneficiario
                          </Typography>
                          <Typography fontSize={'0.8rem'}>{bill.nombreProveedor}</Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Nit/Cédula
                          </Typography>
                          <Typography fontSize={'0.8rem'}>{bill.nitproveedor}</Typography>
                        </Stack>

                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Concepto
                          </Typography>
                          <Typography fontSize={'0.8rem'}>{bill.descripcion}</Typography>
                        </Stack>

                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Causado
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill.causado.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction={'column'} spacing={0} flexGrow={{ md: 1 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Descuento
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill?.descuento ? bill?.descuento?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            }) : (0).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Retenido
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill?.retenido?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Anticipos
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill?.anticipo?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Deducción
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill?.deducciones?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            Rte/Fte
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill?.retefuente?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={700} fontSize={'0.8rem'}>
                            I.V.A
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill?.iva?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction={'column'} spacing={0} flexGrow={{ md: 1 }}>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={1}>
                          <Typography fontWeight={600} fontSize={'0.8rem'}>
                            R/IVA
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill?.reteiva?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={600} fontSize={'0.8rem'}>
                            Rte/Ica
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill?.reteica?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={600} fontSize={'0.8rem'}>
                            Pagado
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill?.valorpago?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={600} fontSize={'0.8rem'}>
                            Emergencia
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {' '}
                            {bill?.emergencia?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} gap={2}>
                          <Typography fontWeight={600} fontSize={'0.8rem'}>
                            Vr/Acta
                          </Typography>
                          <Typography fontSize={'0.8rem'}>
                            {bill?.valoracta?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}
                          </Typography>
                        </Stack>
                      </Stack>

                    </>

                  ) :
                    (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={1}>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Egreso
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Dcto
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={2}>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Beneficiario
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Nit/Cédula
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Concepto
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Causado
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Descuento
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Retenido
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={1}>
                                <Link onClick={() => handleShowModalBillComprobante(bill.idcomprobante)} sx={{ cursor: 'pointer' }}>
                                  <Typography fontSize={'0.8rem'} fontWeight={700}>
                                    {bill.idcomprobante}
                                  </Typography>
                                </Link>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontSize={'0.8rem'}>
                                  {bill.iddctopro}
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={2}>
                                <Typography fontSize={'0.8rem'}>
                                  {bill.nombreProveedor}
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontSize={'0.8rem'}>
                                  {bill.nitproveedor}
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontSize={'0.8rem'}>
                                  {bill.descripcion}
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontSize={'0.8rem'}>
                                  {bill.causado.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.descuento ? bill?.descuento?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  }) : (0).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell colSpan={1}>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.retenido?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Anticipos
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Deducción
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Rte/Fte
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  I.V.A
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  R/IVA
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Rte/Ica
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Pagado
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Emergencia
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontWeight={700} fontSize={'0.8rem'}>
                                  Vr/Acta
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.anticipo?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.deducciones?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.retefuente?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.iva?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.reteiva?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.reteica?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.valorpago?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.emergencia?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography fontSize={'0.8rem'}>
                                  {bill?.valoracta?.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>

                    )
                }
                <Stack alignItems={'end'} p={2}>
                  <Button
                    variant="contained"
                    id="basic-button"
                    onClick={(e) => handleClick(e, bill.documentosFactura)}
                    sx={{ textTransform: 'none' }}
                  >
                    Documentos de la factura
                  </Button>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                      sx: {
                        boxShadow: 'none',
                        mt: 1.5,
                        ml: 0.75,
                        width: 200,
                        '& .MuiMenuItem-root': {
                          typography: 'body2',
                        },
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setShowModal(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <Iconify icon="ph:eye" color="#9a0415" />
                      </ListItemIcon>
                      <ListItemText> Ver adjuntos</ListItemText>
                    </MenuItem>
                    {
                      bill?.documentosFactura !== null && (
                        <MenuItem
                          onClick={() => {
                            downloadAllFiles(bill?.documentosFactura);
                            handleClose();
                            setAttachFilesLocal(attachFilesActa)
                          }}
                        >
                          <>
                            <ListItemIcon>
                              <Iconify icon="material-symbols:download-sharp" color="#08660D" />
                            </ListItemIcon>
                            <ListItemText> Descargar todos</ListItemText>
                          </>
                        </MenuItem>
                      )}
                  </Popover>
                </Stack>
              </Stack>
            </AccordionDetails >
          </Accordion >
        ))
      }
      {
        showModalBillComprobante &&
        <ModalBillComprobante open={showModalBillComprobante} handleClose={handleCloseBillComprobante} idComprobante={comprobante} />
      }
    </Box >
  );
};

export default TableBillsActas;
